.shoe-wrapper {
  width: 300px;
  height: 500px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 3rem 2rem;

  a {
    text-decoration: none;
    color: inherit;
  }

  h5 {
    min-height: 80px;
  }

  img {
    width: 300px;
  }
}

.shoe-image {
  position: relative;

  h5 {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0);
  }

  img {
    cursor: pointer;
  }
}

.shoe-image::after {
  content: "View details";
  color: #fff;
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: all;
  cursor: pointer;
  text-decoration: underline;
}

.shoe-image:hover::after {
  display: flex;
  animation: slide-up 0.2s ease-in;
}

@keyframes slide-up {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
