@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Archivo+Black&display=swap');

*,
::before,
::after {
  margin: 0;
  box-sizing: border-box;
}

body {
  scroll-behavior: smooth;
  font-family: Poppins, sans-serif;
  background-color: #fff;
}

a {
  cursor: pointer;
  text-decoration: none;
}

ul {
  list-style-type: none;
}
