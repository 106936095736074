@import "../app.scss";

.feature-wrapper {
  background: linear-gradient(to top, #414345, #3f444a);
  height: 100vh;
}

nav {
  padding: 1rem 1rem 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  ul {
    display: flex;
    flex-direction: row;
    list-style-type: none;

    li {
      margin-right: 1rem;
    }
  }
}

.feature-hero {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  height: 100vh;
  width: 100vw;

  img {
    width: 600px;
  }

  h1 {
    color: #fff;
    font-weight: bold;
    text-shadow: 0.5rem 0.5rem #3B3D41;
    font-family: "Archivo Black", sans-serif;
  }
}

@media screen and (max-width: 768px) {
  .feature-hero {
    flex-direction: column-reverse;

    img {
      width: 200px;
    }

    h1 {
      font-size: 4rem;
    }
  }
}
